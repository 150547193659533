// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$whitesmoke: rgb(245, 245, 245); // #f5f5f5
$whitesmoke-7f7: rgb(243, 247, 247); //#f3f7f7
$black: rgb(0, 0, 0); // #000000
$raisin-black: rgb(36, 36, 36); // #242424
$dirt: rgb(157, 112, 83); //#9d7053
$licorice: rgb(17, 17, 17); //#111111
$cyan-azure: rgb(79, 139, 173); //#4f8bad
$charleston-green: rgb(33, 37, 41); //#212529
$dim-gray: rgb(105, 105, 105); //#696969
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$dim-gray1: rgb(102, 102, 102); // #666666
$sinopia: rgb(206, 49, 0); // #ce3100
$davy-grey: rgb(89, 89, 89); //#595959
$eerie-black: rgb(31, 31, 31); //#1f1f1f
$orioles-orange: rgb(251, 80, 22); //#fb5016
$green-crayola: rgb(18, 183, 106); //#12b76a
$platinum: rgb(230, 230, 230); // #e6e6e6
$red-orange: rgb(255, 77, 79); //#ff4d4f
$bright-navy-blue: rgb(44, 114, 211); // #2c72d3
$light-brilliant-red: rgb(250, 40, 55); // #fa2837
$yankees-blue: rgb(27, 35, 54); // #1b2336
$isabelline: rgb(237, 237, 237); // #ededed
$anti-flash-white: rgb(240, 240, 240); //f0f0f0
$gray-x11: rgb(191, 191, 191); //#bfbfbf
$seashell: rgb(249, 243, 240); //#f9f3f0
$columbia-blue: rgb(204, 234, 219); //#cceadb
$vivid-tangelo: rgb(243, 112, 33); //#f37021
$licorice414: rgb(20, 20, 20); //#141414
$prussian-blue: rgb(1, 45, 90); //#012d5a
$shell-pink: rgb(249, 236, 228); //#f9ece4
$crystal-tide: rgb(1, 25, 49); //#011931
$dark-gray: rgb(169, 169, 169); //#a9a9a9
$raisin-black-222: rgb(34, 34, 34); //#222222
$sonic-silver: rgb(112, 112, 114); //#707072

// Overlays
$black-02: rgba($black, 0.2);
$black-025: rgba($black, 0.25);
$black-045: rgba($black, 0.45);
$black-006: rgba($black, 0.06);
$black-060: rgba($black, 0.6);
$black-085: rgba($black, 0.85);
$white-08: rgba($white, 0.8);
$licorice414-025: rgba($licorice414, 0.25);
$licorice414-07: rgba($licorice414, 0.7);
$vivid-tangelo-085: rgba($vivid-tangelo, 0.85);
$vivid-tangelo-07: rgba($vivid-tangelo, 0.7);
$cyan-azure-015: rgba($cyan-azure, 0.15);
$cyan-azure-03: rgba($cyan-azure, 0.3);
$licorice-015: rgba($licorice, 0.15);
$dirt-03: rgba($dirt, 0.3);
$dirt-05: rgba($dirt, 0.5);
$columbia-blue-02: rgba($columbia-blue, 0.2);
// Linears
