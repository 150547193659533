.p-frame {
    width: 100%;
    min-height: 100vh;
    position: relative;

    &_ele {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }
}
