.t-footer {
    background-color: $white;
    position: relative;
    padding: rem(32) 0 rem(20);
    border-top: 1px solid rgba($dim-gray1, 0.2);

    &_top {
        @include mobile-down {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &_col {
        margin-bottom: rem(20);

        &_logo {
            max-width: rem(135);
            width: 100%;
            cursor: pointer;
        }

        &_right {
            @include adjust-flex(flex-end, flex-start);
            
            @include mobile-down {
                justify-content: center;
                margin-bottom: rem(20);
            }
        }

        li {
            list-style-type: none;
            margin-bottom: rem(8); 
        }

        &_info {
            margin-top: rem(20);

            &-item {
                margin-top: rem(4);
            }
        }
    }

    &_subTitle {
        margin-bottom: rem(20);
    }

    &_link {
        .a-text {
            transition: color $transition ease-in-out;

            &:hover {
                color: $vivid-tangelo;
            }
        }
    }

    &_bottom {
        padding-top: rem(20);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include mobile-down {
            justify-content: center;
        }

        &-item {
            width: 100%;
            max-width: rem(30);
            margin-left: rem(13);
        }
    }

}
