.o-timelineStatusOrdered {
    $root: &;

    &_steps {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &_dot {
        width: rem(20);
        background: $gray-x11;
        border: rem(5) solid $white;
        border-radius: 50%;
        height: rem(20);
        position: relative;
        margin: auto;

        &-active {
            background: $dirt !important;
        }
    }

    &_step {
        display: block;
        position: relative;
        box-sizing: content-box;
        color: $black-025;
        z-index: 99;

        &-text {
            position: relative;
        }

        &-active {
            color: $dirt;

            &::before {
                background-color: $dirt !important;
            }

        }

        &-textActive {
            span {
                color: $black-085 !important;
                font-weight: 600;
            }

        }

        &-textActiveP {
            p {
                color: $black-085 !important;
            }
        }

        &:not(&:last-child) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 0.2em;
                background-color: $anti-flash-white;
                top: 50%;
                left: 50%;
                transform: translateY(-50%);
            }
        }

        &:first-child {
            margin-left: 0;
        }

        p {
            width: 100%;
            position: absolute;
            top: rem(68);
            color: $black-045;
            max-width: rem(200);
            text-align: center;
            margin-right: rem(8);
            @include font-base(16, 24);
            @include tablet-down {
                @include font-base(12, 14);
            }

        }

        span {
            position: absolute;
            top: rem(38);
            left: 50%;
            margin-right: rem(8);
            transform: translateX(-50%);
            white-space: nowrap;
            color: $black-045;
            @include font-base(16, 24);
        }

    }	
}

.o-timelineStatusMobileOrdered {
    &_steps {
        width: 100%;
    }

    &_step {
        display: flex;
        align-items: center;
        z-index: 99;
        position: relative;

        &-text {
            margin-left: rem(12);
            margin-top: rem(24);

            p {
                width: 100%;
                @include font-base(16, 24);
                color: $black-045;
    
            }
    
            span {
                color: $black-045;
                @include font-base(16, 24);
            }
        }

        &-active {
            color: $dirt;

            &::before {
                background-color: $dirt !important;
            }

        }

        &-textActive {
            span {
                color: $black-085 !important;
                font-weight: 600;
            }

        }

        &-textActiveP {
            p {
                color: $black-085 !important;
            }
        }

        &:not(&:last-child) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: rem(5);
                height: 100%;
                background-color: $anti-flash-white;
                top: 50%;
                left: 10px;
                transform: translateX(-50%);
            }
        }
    }

    &_dot {
        width: rem(20);
        background: $gray-x11;
        border: rem(5) solid $white;
        border-radius: 50%;
        height: rem(20);
        z-index: 999;
        flex-shrink: 0;

        &-active {
            background: $dirt !important;
        }
    }

}
