.a-input {
    $root: &;
    position: relative;
    @include font-base(12, 22);

    &_input {
        padding: rem(5) rem(12);
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        height: rem(32);
        font-family: $font-san-francisco-display;
        font-weight: 400;
        color: $black-085;
        border: 1px solid $gainsboro;
        border-radius: rem(2);
        background: $white;
        

        &::placeholder {
            color: $black-025;
        }

        &:focus {
            #{$root} {
                border: 1px solid $dirt;
            }
        }

        &:disabled {
            background-color: $whitesmoke;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* stylelint-disable-next-line selector-no-qualifying-type */
    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

    &-amount {
        #{$root}_input {
            padding-right: rem(22);
        }

        #{$root}_actions {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: rem(22);
            height: 100%;
            display: flex;
            flex-direction: column;
            border-left: 1px solid $black-006;

            &_item {
                flex: 0 0 50%;
                background-repeat: no-repeat;
                background-size: 12px 12px;
                background-position: 50% 50%;
                cursor: pointer;

                &:first-child {
                    border-bottom: 1px solid $black-006;
                }

                &.increase {
                    background-image: url('~assets/icons/keyboard_arrow_up.svg');
                }

                &.decrease {
                    background-image: url('~assets/icons/keyboard_arrow_down.svg');
                }
            }
        }
    }

    &-search {
        @include font-base(16, 24);

        #{$root}_input {
            padding-top: rem(8);
            padding-bottom: rem(8);
            padding-right: rem(83);
            height: rem(48);
            border-style: solid;
            border-radius: 2px;
            border: 1px solid $gainsboro;
        }

        #{$root}_btnSearch {
            position: absolute;
            right: -1px;
            top: 0;
            bottom: 0;
            width: rem(83);
            height: rem(48);
            background-color: $dirt;
            @include adjust-flex();
            border-top-right-radius: rem(2);
            border-bottom-right-radius: rem(2);
            cursor: pointer;
        }
    }

    &-headerSearch {
        @include font-base(12, 18);
        flex: 1;

        #{$root}_input {
            padding: rem(5) rem(32) rem(5) rem(12);

            &::placeholder {
                color: $licorice414-07;
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(21);
            }
        }

        #{$root}_btnSearch {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: rem(32);
            height: 100%;
            @include adjust-flex();
            cursor: pointer;
        }
    }

    &-formContact {
        @include font-base(16, 24);

        #{$root}_input {
            padding-top: rem(8);
            padding-bottom: rem(8);
            height: rem(40);
            border-style: solid;
            border-radius: rem(4);
            border: 1px solid $white;
            color: $black-060;
        }
    }

    &-brandSearch {
        @include font-base(14, 21);
        // flex: 1;

        #{$root}_input {
            color: $licorice414;
            font-weight: 500;
            padding: rem(12) rem(62) rem(12) rem(16);
            width: 100%;
            height: rem(44);
            border-radius: rem(8);
            border-color: $prussian-blue;
            background-color: $white;

            &::placeholder {
                color: $licorice414-07;
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(21);
            }
        }

        #{$root}_btnSearch {
            position: absolute;
            right: 3px;
            top: 3px;
            bottom: 3px;
            width: rem(59);
            height: rem(38);
            background-color: $dark-gray;
            @include adjust-flex();
            border-top-right-radius: rem(8);
            border-bottom-right-radius: rem(8);
            cursor: pointer;
        }
    }

    &-error {
        border-color: $red-orange !important;
        color: $red-orange;
        #{$root}_input {
            border-color: $red-orange !important;
            color: $red-orange;
        }
    }

    &_error {
        color: $red-orange;
        display: block;
        margin-top: rem(4);
        opacity: 0.8;
        @include font-base(12, 16);
    }
}
