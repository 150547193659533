.t-status {
    @include adjust-flex;
    flex-direction: column;
    min-height: 100vh;

    .a-text {
        max-width: rem(773);
    }

    .a-button {
        width: rem(233);
        margin-top: rem(60);

        @include mobile-down {
            margin-top: rem(30);
        }
    }
}
