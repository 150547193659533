.o-collapse {
    box-shadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.15);
    border-radius: rem(4);
    padding: rem(18) rem(24);

    @include mobile-down {
        padding: rem(18) rem(16);
    }

    &_head {
        @include adjust-flex(space-between, center);
        cursor: pointer;
    }

    &_icon {
        width: rem(20);
        height: rem(20);
        margin-left: rem(8);
        border-radius: 50%;
        position: relative;
        background-color: $vivid-tangelo;
        flex-shrink: 0;

        &_line {
            width: rem(12);
            height: rem(1.5);
            background-color: $white;
            transition: transform 0.3s ease;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &_rotate {
                transform: rotate(90deg) translateY(50%);
                left: 25%;
            }
        }
    }

    &_content {
        margin-top: rem(16);
    }
}
