.o-popupConfirm {
    .a-icon {
        margin: auto;
    }

    &_btn {
        .a-button {
            margin-left: rem(20);
            margin-right: rem(20);
        }
    }
}
