// fonts
$font-path: '~assets/fonts/';
$font-inter: 'Inter';
$font-san-francisco-display: 'San Francisco Display';
// transition
$transition: 0.25s;
$transition-medium: 0.3s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-1: 1;
$z-loading: 999;

// space
$height-header-desktop: 100;
$height-header-desktop-scroll: 76;
$height-header-tablet-down: 65;

$standard-viewport: 1366px;
