.t-contact {
    padding-top: rem(72);
    padding-bottom: rem(120);

    @include tablet-down {
        padding-bottom: rem(70);
    }

    @include mobile-down {
        padding-top: rem(50);
        padding-bottom: rem(50);
    }

    &_title {
        margin-bottom: rem(40);

        @include mobile-down {
            margin-bottom: rem(30);
        }
    }

    &_left {
        &_title {
            margin-top: rem(14);
            margin-bottom: rem(26);
        }

        &_description {
            margin-bottom: rem(40);
        }
    }

    &_right {
        padding: rem(24) rem(32);
        background-color: $prussian-blue;
        border-radius: rem(4);

        @include mobile-down {
            padding: rem(20);
        }

        &-label {
            margin-bottom: rem(8);

            .require {
                color: $vivid-tangelo;
                margin-left: rem(3);
            }
        }

        &-title {
            margin-bottom: rem(35);
        }

        &-button {
            margin-top: rem(16);
            display: flex;
            justify-content: flex-end;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            @include tablet-down {
                margin-top: rem(30);
            }
        }

        &-input {
            @include tablet-down {
                margin-top: rem(16);
            }
        }
    }
}
