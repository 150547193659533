.p-reviewOrder {
    padding-top: rem(16);
    padding-bottom: rem(50);

    &_item {
        margin-bottom: rem(16);
    }

    &_total {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-price {
            margin-left: rem(24);
            @include mobile-down {
                margin-left: rem(12);
            }
        }
    }

    &_button {
        max-width: rem(205);
        width: 100%;
        margin-left: auto;
        margin-top: rem(20);
    }
}
