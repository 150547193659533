@mixin aspect-ratio($width: 1, $height: 1) {
    padding-bottom: ($height / $width) * 100%;
}

.a-image {
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: $transition-long ease;
    width: 100%;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }

    &-636x395 {
        @include aspect-ratio(636, 395);
    }

    &-636x589 {
        @include aspect-ratio(636, 589);
    }

    &-286x264 {
        @include aspect-ratio(286, 264);
    }

    &-199x84 {
        @include aspect-ratio(199, 84);
    }

    &-150x39 {
        @include aspect-ratio(150, 39);
    }

    &-127x33 {
        @include aspect-ratio(127, 33);
    }

    &-135x78 {
        @include aspect-ratio(135, 78);
    }

    &-143x96 {
        @include aspect-ratio(143, 96);
    }

    &-210x149 {
        @include aspect-ratio(210, 149);
    }

    &-356x350 {
        @include aspect-ratio(356, 350);
    }

    &-cover {
        img {
            object-fit: cover;
        }
    }

    &-contain {
        img {
            object-fit: contain;
        }
    }

    &-fill {
        img {
            object-fit: fill;
        }
    }
}
