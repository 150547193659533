.a-checkbox {
    $root: &;

    &_wrapper {
        @include adjust-flex;
        cursor: pointer;
        width: fit-content;
    }

    input {
        position: relative;
        appearance: none;
        cursor: pointer;
        width: rem(17);
        height: rem(17);
        border: rem(2) solid $davy-grey;
        border-radius: rem(2);
        transition: border-color $transition ease-in-out;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: rem(2);
            border-radius: rem(10);
            background-color: $dirt;
            top: 50%;
            left: 50%;
            opacity: 0;
            transition: opacity $transition ease-in-out;
        }

        &::before {
            width: rem(9);
            transform: translate(-30%, -50%) rotate(-55deg);
        }

        &::after {
            width: rem(6);
            transform: translate(-80%, 30%) rotate(45deg);
        }

        &:checked {
            border-color: $dirt;

            &::before,
            &::after {
                opacity: 1;
            }
        }
    }

    &-secondary {
        border: rem(1) solid $gray-x11;
        padding: rem(7) rem(8);
        border-radius: rem(4);
        cursor: pointer;

        #{$root}_wrapper {
            width: 100%;
            justify-content: flex-start;
        }

        &-active {
            background-color: $shell-pink;
            border: rem(1.5) solid $vivid-tangelo;
        }

        .a-text {
            @include font-base(18, 16);

            @include tablet-down {
                @include font-base(14, 16); }
        }

        input {
            visibility: hidden;
            width: 0;
            height: rem(24);
            border: 0;
            margin-right: 0;
            flex-shrink: 0;

            &::before {
                display: none;
            }

            &::after {
                width: 100%;
                height: 100%;
                background: url('~assets/icons/ic_checked_circle.svg');
                background-size: cover;
                transform: translateY(-50%);
                border-radius: 0;
                left: 0;
            }

            &:checked {
                visibility: visible;
                width: rem(24);
                margin-right: rem(8);
            }
        }
    }
}
