.o-popupIndustryOfInterest {
    &_list {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        // margin: 0 rem(-34);
        padding: rem(24) rem(29);
        box-shadow: inset 0 rem(1) 0 #f0f0f0;

        @include tablet-down {
            margin: 0 rem(-20);
        }

        @include mobile-down {
            margin: 0 rem(-10);
        }
    }

    &_item {
        flex-basis: 20%;
        padding: rem(18) rem(34);

        .a-checkbox {
            height: 100%;
        }

        @include tablet-down {
            flex-basis: calc(100% / 3);
            padding: rem(10) rem(20);
        }

        @include mobile-down {
            flex-basis: 50%;
            padding: rem(5) rem(10);
        }
    }

    &_bottom {
        background: $white;
        box-shadow: inset 0 rem(1) 0 #f0f0f0;
        @include adjust-flex(flex-end, center); 
        padding: rem(10) rem(16);

        .a-button {
            @include font-base(16, 20);
        }
    }

    &_guideOrderContent {
        box-shadow: inset 0 rem(1) 0 #f0f0f0;
        max-width: 100%;
        overflow: auto;
        height: rem(450);
    }
}
