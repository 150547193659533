.o-shopNowImgCard {
    position: relative;
    border-radius: rem(8);
    cursor: pointer;

    &_discount {
        border-left: rem(88) solid $vivid-tangelo-085;
        border-bottom: rem(25) solid transparent;
        min-height: rem(97);
        height: fit-content;
        width: 0;
        position: absolute;
        top: rem(-18);
        right: rem(12);
        z-index: 2;
        padding-bottom: rem(30);

        @include mobile-down {
            border-left: rem(66) solid $vivid-tangelo-085;
        }

        .a-text {
            position: relative;
            width: rem(88);
            left: rem(-88);
            top: rem(18);
            text-align: center;
            padding: 0 rem(3);
            z-index: 2;

            @include mobile-down {
                @include font-base(14, 21);
                width: rem(66);
                left: rem(-66);
                padding: 0 rem(2);
            }
        }
        
        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: rem(88);
            border-top: rem(20) solid transparent;
            border-right: rem(16) solid $vivid-tangelo;

            @include mobile-down {
                right: rem(66);
            }
        }
    }

    &_layer {
        position: absolute;
        top: rem(20);
        left: rem(24);
        z-index: 2;

        @include tablet-down {
            top: rem(25);
            left: rem(25);
        }

        .a-heading {
            margin-bottom: rem(15);
        }

        &_title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }

    &_btn {
        .a-button {
            max-width: rem(150);

            @include small-mobile {
                padding: rem(6) rem(12);
            }
        }
    }

    .a-image {
        border-radius: rem(8);
    }

    &_background {
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $licorice414-025;
            z-index: 1;
        }
    }
}
