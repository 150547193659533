html,
body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
    margin: 0;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

img,
svg {
    vertical-align: inherit;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

$offsets: (top, bottom, left, right);
$breakpoints: (
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
);

@each $offset in $offsets {
    @for $spacing from -100 through 100 {
        @if $spacing >= 0 {
            .u-m#{str-slice($offset, 0, 1)}-#{$spacing} {
                margin-#{$offset}: #{$spacing}px;
            }
            .u-p#{str-slice($offset, 0, 1)}-#{$spacing} {
                padding-#{$offset}: #{$spacing}px;
            }
        } @else {
            .u-m#{str-slice($offset, 0, 1)}-negative#{$spacing} {
                margin-#{$offset}: #{$spacing}px;
            }
        }
    }
}

@each $name, $value in $breakpoints {
    @each $offset in $offsets {
        @for $spacing from -100 through 100 {
            @if $spacing >= 0 {
                .u-m#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
                    @media #{$value} {
                        margin-#{$offset}: #{$spacing}px;
                    }
                }

                .u-p#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
                    @media #{$value} {
                        padding-#{$offset}: #{$spacing}px;
                    }
                }
            } @else {
                .u-m#{str-slice($offset, 0, 1)}-negative-#{$name}#{$spacing} {
                    @media #{$value} {
                        margin-#{$offset}: #{$spacing}px;
                    }
                }
            }
        }
    }
}

@each $name, $value in $breakpoints {
    @for $spacing from -500 through 500 {
        .minw-#{$name}-#{$spacing} {
            @media #{$value} {
                min-width: #{$spacing}px;
            }
        }
        .maxw-#{$name}-#{$spacing} {
            @media #{$value} {
                max-width: #{$spacing}px;
            }
        }
    }
}

.h-100 {
    height: 100%;
}
