.p-trackingPageContainer {
    &_title {
        padding-top: rem(70);

        @include mobile-down {
            padding-top: rem(30);
        }
    }

    &_search {
        padding-top: rem(50);
    }

    &_timelineStatusOrdered {
        padding-top: rem(80);
        @include tablet-down {
            margin-bottom: rem(90);
            padding-top: rem(50);
        }
        
        &-item {
            margin-bottom: rem(20);
            margin-top: rem(20);
            min-height: rem(250);

            &-timeline {
                margin-bottom: rem(40);
            }

            &-sub {
                padding-left: rem(30);
                margin-bottom: rem(40);

                @include mobile-down {
                    padding-left: rem(20);
                }
            }
        }
    }
}
