.t-frequentlyAskedQuestions {
    margin-top: rem(72);

    &_content {
        padding-left: rem(120);
        padding-right: rem(120);

        @include desktop-down {
            padding-left: rem(80);
            padding-right: rem(80);
        }

        @include tablet-down {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &_heading {
        margin-bottom: rem(40);
        text-align: center;
    }

    &_list {
        margin: rem(-10) 0;

        &_item {
            padding: rem(10) 0;
        }
    }
}
