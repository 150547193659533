.o-carousel {
    $root: &;
    position: relative;

    .heroBanner {
        background: $white;
    }

    &_arrow {
        $arrow: &;
        background: transparent;
        border-radius: 50%;
        height: rem(36);
        width: rem(36);
        z-index: $z-1;
        padding: rem(10);
        transition: all 0.1s ease-in-out;
        border: 1px solid $vivid-tangelo;

        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 100%;
            width: 100%;
        }

        &:not(.slick-disabled):hover {
            &#{$arrow}_prev {
                background: $gainsboro;

                &::before {
                    background-size: rem(16) rem(16);
                    background-image: url('~assets/icons/ic_prev.svg');
                }

                &-defaultArrow {
                    background: $gainsboro;

                    &::before {
                        background-size: 100% 100%;
                        background-image: url('~assets/icons/ic_prev.svg');
                    }
                }
            }

            &#{$arrow}_next {
                background: $gainsboro;

                &::before {
                    background-size: rem(16) rem(16);
                    background-image: url('~assets/icons/ic_next.svg');
                }

                &-defaultArrow {
                    background: $gainsboro;

                    &::before {
                        background-size: 100% 100%;
                        background-image: url('~assets/icons/ic_nextWhite.svg');
                    }
                }
            }
        }

        &_prev {
            cursor: pointer;

            &-defaultArrow {
                left: rem(-49);
                background: $white;

                &::before {
                    background-image: url('~assets/icons/ic_prev.svg');
                }
            }

            &-heroBannerArrow {
                left: calc((60 / 1366) * 100%);

                @include desktop-down {
                    display: none;
                }

                &::before {
                    background-size: rem(16) rem(16);
                    background-image: url('~assets/icons/ic_prev.svg');
                }
            }

        }

        &_next {
            cursor: pointer;

            &-defaultArrow {
                right: rem(-49);
                background: $white;

                &::before {
                    background-image: url('~assets/icons/ic_next.svg');
                }
            }

            &-heroBannerArrow {
                right: calc((60 / 1366) * 100%);

                @include desktop-down {
                    display: none;
                }

                &::before {
                    background-size: rem(16) rem(16);
                    background-image: url('~assets/icons/ic_next.svg');
                }
            }

        }

    }
}

.slick-dots {
    font-size: initial;

    li {
        width: rem(12);
        height: rem(12);
        margin-left: rem(4);
        margin-right: rem(4);

        @include max-desktop-down {
            width: rem(8);
            height: rem(8);
        }

        button {
            padding: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $whitesmoke;

            &::before {
                content: none;
            }
        }
    }

    .slick-active button {
        background: $sonic-silver;
    }
}
