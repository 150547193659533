.p-tutorial {
    &_title {
        margin-top: rem(72);

        @include mobile-down {
            margin-top: rem(30);
        }
    }

    &_content {
        margin-top: rem(32);
        margin-bottom: rem(124);

        @include mobile-down {
            margin-bottom: rem(60);
        }

        img,
        video,
        table {
            max-width: 100%;
            width: 100%;
        }
    }
}
