.a-heading {
    $root: &;
    margin: 0;

    &-65x90 {
        @include font-base(65, 90);

        @include tablet-down {
            @include font-base(42, 63);
        }

        @include mobile-down {
            @include font-base(32, 45);
        }
    }

    &-60x90 {
        @include font-base(60, 90);

        @include tablet-down {
            @include font-base(39, 63);
        }

        @include mobile-down {
            @include font-base(29, 45);
        }
    }

    &-46x64 {
        @include font-base(46, 64);

        @include tablet-down {
            @include font-base(30, 42);
        }

        @include mobile-down {
            @include font-base(21, 31);
        }
    }

    &-36x51 {
        @include font-base(36, 51);

        @include tablet-down {
            @include font-base(26, 37);
        }
        @include mobile-down {
            @include font-base(24, 32);
        }
    }

    &-34x48 {
        @include font-base(34, 48);
        @include tablet-down {
            @include font-base(28, 40);
        }
        @include mobile-down {
            @include font-base(22, 31);
        }
    }

    &-24x30 {
        @include font-base(24, 30);
    }

    &-40x60 {
        @include font-base(40, 60);

        @include desktop-down {
            @include font-base(28, 38);
        }

        @include mobile-down {
            @include font-base(18, 28);
        }
    }

    &-18x23 {
        @include font-base(18, 23);
    }

    &-isLine {
        border-left: rem(4) solid $vivid-tangelo;
        padding-left: rem(20);
    }

    @include letter-spacing();
    @include style-font-weight();
    @include style-general();
    @include style-color();
}
