.p-thanks {
    padding-top: rem(96);
    padding-bottom: rem(186);

    @include tablet-down {
        padding-top: rem(40);
        padding-bottom: rem(100);
    }

    &_heading {
        margin-top: rem(24);
        margin-bottom: rem(32);
    }

    &_button {
        display: flex;
        justify-content: center;

        .a-button {
            display: flex;
            align-items: center;
            width: fit-content;

            .a-icon {
                margin-right: rem(4);
            }
        }
    }
}
