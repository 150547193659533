.t-forYou {
    &_heading {
        margin-bottom: rem(30);
        @include adjust-flex(space-between); }

    &_content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 rem(-14.5) rem(-24);

        @include mobile-down {
            display: block;
        }
    }
    
    &_item {
        flex-basis: calc(100% / 3);
        padding: 0 rem(14.5) rem(24);
    }

    .a-link {
        cursor: pointer;
    }
}
