.t-mainLayout {
    min-height: calc(100vh - 151px - 180px);
    overflow-x: hidden;

    @include tablet-down {
        min-height: calc(100vh - 155px - 100px);
    }

    &_scrollUpBtn {
        position: fixed;
        bottom: rem(200);
        right: rem(40);
        outline: none;
        background-color: $dirt;
        border: 0;
        border-radius: rem(6);
        padding: rem(10) rem(5);
        opacity: 0;
        visibility: hidden;
        transition: all $transition ease-in-out;

        @include mobile-down {
            right: rem(16);
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.t-mainLayout:has(.p-thanks) {
    min-height: 65vh;
}
