.t-otherBrands {
    &_title {
        position: relative;
        margin-bottom: rem(38);

        @include mobile-down {
            margin-bottom: rem(24);
        }

        &_heading {
            @include adjust-flex(space-between);
        }

        .a-heading {
            margin-bottom: rem(12);
        }
    }

    &_list {
        margin: 0 rem(-16);

        @include tablet-down {
            margin: 0 rem(-8);
        }

        @include mobile-down {
            margin: 0;
        }

        &_item {
            padding: rem(16);

            @include tablet-down {
                padding: rem(16) rem(-8);
            }

            @include mobile-down {
                padding: rem(16) 0 rem(6);
            }
        }
    }
}
