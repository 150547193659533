.t-feedbackOfCustomer {
    margin: 0 rem(-16);
    @include tablet-down {
        margin: 0 rem(-8);
    }
    @include mobile-down {
        margin: 0;
    }

    &_item {
        padding: rem(6) rem(16);
        @include tablet-down {
            padding: rem(6) rem(-8);
        }
        @include mobile-down {
            padding: rem(6);
        }
    }

    .o-carousel_arrow_prev-defaultArrow {
        left: rem(-8);

        &.slick-prev {
            @include tablet-down {
                top: unset;
                bottom: -80px;
                left: 42%;
            }
            @include mobile-down {
                left: 35%;
            }
        }
    }

    .o-carousel_arrow_next-defaultArrow {
        right: rem(-8);

        &.slick-next {
            @include tablet-down {
                top: unset;
                bottom: -80px;
                right: 42%;
            }
            @include mobile-down {
                right: 35%;
            }
        }
    }
}
