.o-showNowCard {
    $root: &;
    padding: rem(10);
    border-radius: rem(5);
    box-shadow: 0 rem(4) rem(25) $cyan-azure-015;
    background-color: $white;

    &_thumbnail {
        position: relative;
        overflow: hidden;
        border-radius: rem(5);
        margin-bottom: rem(12);
    }

    // &_actionGroup {
    //     position: absolute;
    //     bottom: rem(20);
    //     left: 50%;
    //     transform: translateX(-50%);
    //     @include adjust-flex(space-between);
    //     transition: opacity $transition ease-in-out;
    //     opacity: 0;
    //     visibility: hidden;

    //     &-item {
    //         position: relative;
    //         cursor: pointer;
    //         width: rem(35);
    //         height: rem(35);
    //         border-radius: rem(3);
    //         background-color: $dirt;
    //         transition: background-color $transition ease-in-out;

    //         &:hover {
    //             background-color: $licorice;
    //         }

    //         &:not(:last-child) {
    //             margin-right: rem(8);
    //         }

    //         .a-icon {
    //             position: absolute;
    //             left: 50%;
    //             top: 50%;
    //             transform: translate(-50%, -50%);
    //         }
    //     }
    // }

    &_content {
        cursor: pointer;
        padding: rem(16) rem(5);
        @include text-overflow(1);

        &:hover {
            .a-text {
                color: $dirt;
            }
        }
    }

    // &_tooltip {
    //     position: absolute;
    //     top: -100%;
    //     left: 50%;
    //     transform: translate(-50%, -25%);
    //     width: max-content;
    //     background-color: $licorice;
    //     padding: rem(4) rem(8);
    //     border-radius: rem(3);
    //     opacity: 0;
    //     visibility: hidden;
    //     z-index: 0;
    //     transition: opacity $transition ease-in-out;

    //     &::before {
    //         position: absolute;
    //         content: '';
    //         width: rem(13);
    //         height: rem(13);
    //         background-color: $licorice;
    //         bottom: 0;
    //         left: 50%;
    //         z-index: -1;
    //         transform: translate(-50%, 25%) rotate(45deg);
    //     }

    //     &.show {
    //         opacity: 1;
    //         visibility: visible;
    //     }
    // }

    &:hover {
        #{$root} {
            &_thumbnail {
                .a-image {
                    transform: scale(1.1);
                }
            }

            // &_actionGroup {
            //     opacity: 1;
            //     visibility: visible;
            // }
        }
    }
}
