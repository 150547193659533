.t-questionCategory {
    padding-top: rem(72);

    &_heading {
        @include adjust-flex();
    }

    &_img {
        max-width: rem(150);
        width: 100%;
        cursor: pointer;

        @include mobile-down {
            display: none;
        }
    }

    &_title {
        text-align: center;
        margin-left: rem(40);

        @include mobile-down {
            margin-left: 0;
        }
    }

    &_content {
        margin-top: rem(85);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: rem(120);
        padding-right: rem(120);
        margin-left: rem(-20);
        margin-right: rem(-20);

        @include desktop-down {
            padding-left: rem(80);
            padding-right: rem(80);
        }

        @include tablet-down {
            margin-top: rem(40);
            margin-left: rem(-12);
            margin-right: rem(-12);
            padding-left: 0;
            padding-right: 0;
        }

        @include mobile-down {
            margin-top: rem(40);
        }
    }

    &_item {
        flex: 0 0 calc(100% / 3);
        margin-bottom: rem(10);
        padding-left: rem(20);
        padding-right: rem(20);

        &-text {
            width: 100%;
            min-height: rem(104);
            border-radius: rem(4);
            cursor: pointer;
            background-color: $vivid-tangelo;
            color: $white;
            @include adjust-flex();

            &:hover {
                background-color: $prussian-blue;
                color: $vivid-tangelo;
            }
        }

        @include desktop-down {
            flex: 0 0 calc(100% / 3);
        }

        @include tablet-down {
            flex: 0 0 calc(100% / 2);
            padding-left: rem(12);
            padding-right: rem(12);
        }

        @include mobile-down {
            flex: 0 0 80%;
        }
    }
}
