.p-home {
    $root: &;

    section {
        &:not(:first-child) {
            padding: rem(70) 0 rem(50);
            @include tablet-down {
                padding: rem(32) 0 rem(32);
            }
        }

        &#{$root}_otherBrands {
            @include tablet-down {
                padding-bottom: rem(42);
            }
        }
    }

    &_features,
    &_brands {
        background-color: $whitesmoke-7f7;
        padding-bottom: rem(40) !important;
    }

    &_features {
        background-color: $whitesmoke-7f7;
        // margin-bottom: rem(60);

        // @include tablet-down {
        //     margin-bottom: rem(42);
        // }
    }

    &_feedbackOfCustomer {
        background-color: $seashell;
    }
}
