.t-header {
    &_wrapUpper {
        padding: rem(8) 0;
        background-color: $white;
    }

    &_upper {
        @include adjust-flex(flex-end, center);

        @include tablet-down {
            flex-direction: column;
        }

        &-icon {
            width: rem(16);
            height: rem(16);

            img {
                height: 100%;
                width: 100%;
            }
        }

        &-hotline {
            .a-link {
                transition: $transition;

                &:hover {
                    color: $sonic-silver;
                }
            }
        }

        &-list {
            list-style: none;
            @include adjust-flex(start, center);

            &-trackOder {
                .a-text {
                    transition: $transition;

                    &:hover {
                        color: $sonic-silver;
                    }
                }
            }

            >li {
                color: $white;
                @include adjust-flex(center, center);
                position: relative;
                cursor: pointer;

                &:not(&:last-child) {
                    margin-right: rem(38);

                    &::after {
                        position: absolute;
                        content: '';
                        height: rem(18);
                        width: rem(1);
                        background-color: $licorice;
                        opacity: 0.32;
                        right: rem(-20);
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @include tablet-down {
                        margin-right: rem(12);

                        &::after {
                            right: rem(-6);
                        }
                    }

                }

            }
        }
    }

    &_pulldown {
        $pulldown: &;
        position: relative;

        &-header {
            cursor: pointer;

            .a-icon {
                transition: $transition;
            }

            #{$pulldown}-open & {
                .a-icon {
                    transform: rotate(-180deg);
                }
            }
        }

        &-content {
            border-radius: rem(5);
            padding: rem(3);
            opacity: 0;
            visibility: hidden;
            position: absolute;
            transition: $transition;
            z-index: 99;
            background: $raisin-black;
            margin-top: rem(5);
            border: rem(1) solid $dim-gray;
            box-shadow: 0 0 rem(2) rem(1) $raisin-black;


            #{$pulldown}-open & {
                opacity: 1;
                visibility: visible;
            }

            ul {
                list-style: none;

                li {
                    color: $white;
                    @include font-base(13, 18);
                    @include adjust-flex(start, center);
                    border-radius: rem(5);
                    cursor: pointer;
                    padding: rem(2) rem(2);

                    >.icon-check {
                        visibility: hidden;

                        &-active {
                            visibility: visible;
                        }
                    }

                    .a-icon {
                        height: rem(10);
                        width: rem(10);
                    }

                    &:hover {
                        background: $sonic-silver;
                    }
                }
            }
        }
    }

    &_WrapMain {
        padding: 0;
        background-color: $white;
        box-shadow: 0 rem(4) rem(8) rgba($black, 0.1);

        @include tablet-down {
            padding: rem(10) 0;
        }

        &-showWhenScroll {
            top: 0;
            left: 0;
            width: 100%;
            z-index: 100;
            background-color: $white;
            position: fixed;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            box-shadow: 0 0 rem(20) rem(3) rgba($black, 0.05);
        }
    }

    &_main {
        @include adjust-flex(space-between, center);

        &-left {
            flex-basis: rem(180);
            flex-shrink: 0;

            @include mobile-down {
                flex-basis: rem(86);
            }

            &_logo {
                width: fit-content;
                cursor: pointer;
                margin: 0;
                font-size: rem(36);
                line-height: normal;

                .a-image {
                    width: rem(127);
                    height: rem(33);

                    @include mobile-down {
                        width: rem(86);
                        height: auto;
                    }
                }
            }

            @include tablet-down {
                display: block;
            }
        }

        &-right {
            display: flex;
            justify-content: flex-end;
            flex: 1;

            @include desktop-down {
                justify-content: space-between;
            }

            @include tablet-down {
                justify-content: flex-end;
                flex-grow: 0;
            }

            &-hamberger {
                margin-left: rem(10);
                cursor: pointer;
            }

            &_wrapTool {
                display: flex;
                align-items: center;
                list-style: none;
            }

            &-search {
                @include adjust-flex(flex-end, center);
                width: 100%;
                background-color: $white;

                .a-input_input {
                    border-radius: rem(24);
                    @include font-base(16, 24);
                    background-color: #f4f4f4;
                    border-color: $black;
                    padding-left: rem(32);
                    height: rem(40);

                    @include mobile-down {
                        padding-right: rem(16);
                        height: rem(32);
                    }
                }

                .a-input_btnSearch {
                    left: rem(4);
                    right: auto;
                }

                &-icon {
                    margin-right: rem(8);
                    width: rem(20);
                    height: rem(20);
                    cursor: pointer;
                }

                @include desktop-down {
                    width: 100%;
                    max-width: rem(300);
                    margin-left: rem(20);
                }

                @include mobile-down {
                    margin-left: rem(12);
                }
            }

            &-menu {
                padding-right: rem(12);
                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    height: 80%;
                    width: 1px;
                    top: rem(8);
                    right: 0;
                    background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.15) 25%, rgba($black, 0.3) 50%, rgba($black, 0.15) 75%, rgba($black, 0) 100%);
                }
            }
        }

        &-menu {
            display: flex;
            justify-content: start;
            align-items: center;
            list-style: none;
            flex: 1;

            @include mobile-down {
                margin-left: rem(8);
            }
        }

        &-cart {
            @include adjust-flex(center, center);
            cursor: pointer;
            padding: rem(5) 0 rem(5) rem(12);
            flex-shrink: 0;

            .a-text {
                transition: $transition;
            }
        }

        &-iconCart {
            position: relative;
            margin-right: rem(5);

            @include tablet-down {
                margin-right: 0;
            }
        }

        &-numberCart {
            position: absolute;
            top: rem(-10);
            right: rem(-12);
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 50%;
            width: rem(20);
            height: rem(20);
            @include adjust-flex();
            padding: rem(2);

            &-inside {
                width: 100%;
                height: 100%;
                background-color: $vivid-tangelo;
                border-radius: 50%;
                @include font-base(10, 10);
                font-weight: 500;
                color: $white;
                text-align: center;
                @include adjust-flex();
            }
        }

        &-mobile {
            &-menu {
                position: absolute;
                box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
                left: rem(30);
                right: rem(30);
                z-index: 99;
                transition: all 0.2s ease-in-out;
                margin-top: rem(17);
                visibility: hidden;
                overflow-y: auto;
                overflow-x: hidden;
                @include scroll-bars(8);

                &-show {
                    visibility: visible;
                }
            }
        }
    }
}


.t-header_menu {
    $menu: &;
    display: flex;
    justify-content: flex-end;

    &-submenu {
        flex-direction: column;
        align-items: flex-start;
    }

    &-icon {
        padding-right: rem(8);
    }

    &-item {
        position: relative;
        display: flex;
        align-items: center;

        #{$menu}-submenu & {
            margin-right: 0 !important;
            width: 100%;
        }

        &:not(&:last-child) {
            margin-right: rem(40);
        }
    }

    &-popup {
        box-shadow: 0 0 rem(20) rem(3) rgba($black, 0.05);
        transition: all 0.3s ease-in-out;
        background: $white;
        position: absolute;
        visibility: hidden;
        border-radius: 0;
        display: block;
        width: rem(250);
        border: 0;
        z-index: 99;
        opacity: 0;
        top: rem(80);
        left: 0;
        padding: 0;

        &-submenu {
            right: rem(-250);
            left: auto;
            top: 0;


            &:hover {
                top: 0 !important;
                opacity: 1;
                margin-top: rem(23) !important;
                visibility: visible;
            }
        }

        &:hover {
            top: 100%;
            opacity: 1;
            margin-top: rem(14);
            visibility: visible;
        }

        &-item {
            color: $yankees-blue;
            padding: rem(15);
            border-bottom: rem(1) dashed $isabelline;
            margin: 0;
            @include font-base(16, 25);
            font-weight: 600;
            display: block;
            position: relative;
            cursor: pointer;

            &-active {
                color: $raisin-black-222;
            }

            .a-text {
                transition: $transition;

                &:hover {
                    color: $raisin-black-222;
                }
            }
        }
    }

    &-header {
        padding: rem(20) 0;
        font-weight: 500;
        cursor: pointer;
        @include adjust-flex(space-between, center);
        color: $davy-grey;


        .a-link {
            &.active {
                color: $raisin-black-222;
            }
        }

        &-active {
            color: $raisin-black-222;
        }

        #{$menu}-submenu & {
            padding: rem(15);
            justify-content: space-between;

            .a-text {
                @include font-base(16, 25);
            }

            &:hover+#{$menu}-popup {
                top: 0 !important;
                opacity: 1;
                margin-top: rem(23) !important;
                visibility: visible;
            }
        }

        &:hover {
            color: $raisin-black-222;

            +#{$menu}-popup {
                top: 100%;
                opacity: 1;
                margin-top: rem(14);
                visibility: visible;
            }
        }
    }
}

.t-header_menuMobile {
    $menu-mobile: &;
    background: $white;

    &-itemCollapse {
        padding: rem(11) rem(11) rem(0) rem(34);

        #{$menu-mobile}-submenu & {
            padding-right: 0;
        }

        &-active {
            .m-collapse {
                &_head {
                    filter: invert(49%) sepia(12%) saturate(1305%) hue-rotate(340deg) brightness(93%) contrast(97%);
                }
            }
        }
    }

    &-colItem {
        padding: rem(11);
        position: relative;
        padding-left: rem(34);

        &-active {
            filter: invert(49%) sepia(12%) saturate(1305%) hue-rotate(340deg) brightness(93%) contrast(97%);
        }

        &::after {
            border-bottom: rem(1) solid $isabelline;
            content: '';
            position: absolute;
            bottom: 0;
            left: -100%;
            right: -100%;
            height: rem(1);
            background: $isabelline;
        }
    }

    &-item {
        position: relative;
        padding: rem(11);
        padding-left: rem(34);
        width: 100%;

        &-active {
            filter: invert(49%) sepia(12%) saturate(1305%) hue-rotate(340deg) brightness(93%) contrast(97%);
        }

        &::after {
            border-bottom: rem(1) solid $isabelline;
            content: '';
            position: absolute;
            bottom: 0;
            left: -100%;
            right: -100%;
            height: rem(1);
            background: $isabelline;
        }

        #{$menu-mobile}-submenu & {
            border-bottom: 0;
            padding-right: 0;
        }
    }

    &-title {
        @include adjust-flex(space-between, center);
    }
}
