.o-feedbackCard {
    padding: rem(30);
    background-color: $white;
    box-shadow: 0 rem(4) rem(25) rgb(79 139 173 / 15%);
    border-radius: rem(5);
    position: relative;

    &_wrapAvatar {
        display: flex;
        align-items: center;
        margin-bottom: rem(22);
    }

    &_avatar {
        margin-right: rem(20);

        img {
            height: rem(64);
            width: rem(64);
            border: rem(2) solid $dirt;
            border-radius: 100%;
        }
    }

    &_content {
        margin-bottom: rem(15);
    }

    &_wapRating {
        display: flex;
        cursor: pointer;
    }

    &_quoteIcon {
        position: absolute;
        right: rem(30);
        bottom: rem(30);
    }
}
