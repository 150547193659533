.t-noResultBrand {
    &_heading {
        margin-top: rem(72);

        @include mobile-down {
            margin-top: rem(30);
        }
    }

    &_description {
        margin-top: rem(32);
        margin-bottom: rem(32);

        @include mobile-down {
            margin-top: rem(20);
        }
    }

    &_image {
        max-width: rem(300);
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(60);

        @include mobile-down {
            margin-bottom: rem(40);
        }
    }

    &_button {
        margin-bottom: rem(120);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @include mobile-down {
            margin-bottom: rem(60);
        }
    }
}
