.t-brands {
    &_heading {
        margin-top: rem(72);

        @include mobile-down {
            margin-top: rem(30);
        }
    }

    &_search {
        max-width: rem(706);
        width: 100%;
        margin: rem(32) auto rem(20);

        @include mobile-down {
            margin-top: rem(20);
        }
    }

    &_description {
        margin-bottom: rem(30);
    }

    &_result {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: rem(-9.5);
        margin-right: rem(-9.5);

        &_item {
            padding-left: rem(9.5);
            padding-right: rem(9.5);
            flex: 0 0 calc(100% / 5);
            margin-bottom: rem(28);

            @include desktop-down {
                flex: 0 0 calc(100% / 4);
            }

            @include tablet-down {
                flex: 0 0 calc(100% / 3);
            }

            @include mobile-down {
                flex: 0 0 calc(100% / 2);
            }
        }
    }

    &_pagination {
        @include adjust-flex;
        margin-top: rem(18);
        margin-bottom: rem(120);

        @include tablet-down {
            margin-bottom: rem(80);
        }

        @include mobile-down {
            margin-bottom: rem(60);
        }
    }
}

.t-brand {
    position: relative;
    background-color: $licorice414-025;
    border-radius: rem(8);
    overflow: hidden;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $licorice414-025;
        z-index: 1;
    }

    &_name {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include adjust-flex;
        z-index: 2;
    }
}
