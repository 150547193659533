.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-14x18 {
        width: rem(14);
        height: rem(18);
    }

    &-15 {
        height: rem(15);
        width: rem(15);
    }

    &-14 {
        height: rem(14);
        width: rem(14);
    }

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-18 {
        height: rem(18);
        width: rem(18);
    }

    &-19x21 {
        height: rem(19);
        width: rem(21);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-21x27 {
        width: rem(21);
        height: rem(27);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-35 {
        height: rem(35);
        width: rem(35);
    }

    &-55 {
        height: rem(55);
        width: rem(55);
    }

    &-200 {
        height: rem(200);
        width: rem(200);
    }

    &-error {
        background-image: url('~assets/icons/ic_error.svg');
    }

    &-star {
        background-image: url('~assets/icons/star-inactive.svg');
    }

    &-starActive {
        background-image: url('~assets/icons/star-active.svg');
    }

    &-home {
        background-image: url('~assets/icons/ic_home.svg');
    }

    &-refund {
        background-image: url('~assets/icons/ic_refund.svg');
    }

    &-delivery {
        background-image: url('~assets/icons/ic_delivery-truck.svg');
    }

    &-headphone {
        background-image: url('~assets/icons/ic_headphone.svg');
    }

    &-heart {
        background-image: url('~assets/icons/ic_heart.svg');
    }

    &-swap {
        background-image: url('~assets/icons/ic_swap.svg');
    }

    &-eye {
        background-image: url('~assets/icons/ic_eye.svg');
    }

    &-addCart {
        background-image: url('~assets/icons/ic_addcart.svg');
    }

    &-discount {
        background-image: url('~assets/icons/ic_discount.svg');
    }

    &-shoppingBag {
        background-image: url('~assets/icons/ic_shopping_bag.svg');
    }

    &-shoppingBagBlack {
        background-image: url('~assets/icons/ic_shopping_bag_black.svg');
    }

    &-worldWide {
        background-image: url('~assets/icons/ic_world_wide.svg');
    }

    &-coin {
        background-image: url('~assets/icons/ic_coin.svg');
    }

    &-arrowDown {
        background-image: url('~assets/icons/ic_arrow_down.svg');
    }

    &-arrowDownWhite {
        background-image: url('~assets/icons/ic_arrow_down_white.svg');
    }

    &-arrowDownGray {
        background-image: url('~assets/icons/ic_arrow_down_gray.svg');
    }

    &-checkWhite {
        background-image: url('~assets/icons/ic_check_white.svg');
    }

    &-cart {
        background-image: url('~assets/icons/ic_cart.svg');
    }

    &-whiteCart {
        background-image: url('~assets/icons/ic_white_cart.svg');
    }

    &-hamberger {
        background-image: url('~assets/icons/ic_hamberger.svg');
    }

    &-cancel {
        background-image: url('~assets/icons/ic_cancel.svg');
    }

    &-plus {
        background-image: url('~assets/icons/ic_plus.svg');
    }

    &-minus {
        background-image: url('~assets/icons/ic_minus.svg');
    }

    &-recycleBin {
        background-image: url('~assets/icons/ic_recycle_bin.svg');
    }

    &-close {
        background-image: url('~assets/icons/ic_close.svg');
    }

    &-loading {
        background-image: url('~assets/icons/ic_loading.gif');
    }

    &-arrowUp {
        background-image: url('~assets/icons/ic_up_arrow.svg');
    }

    &-warning {
        background-image: url('~assets/icons/ic_warning.svg');
    }

    &-success {
        background-image: url('~assets/icons/ic_success.svg');
    }

    &-cartBlack {
        background-image: url('~assets/icons/ic_cart_black.svg');
    }

    &-prevArrow {
        background-image: url('~assets/icons/ic_prev.svg');
    }

    &-nextArrow {
        background-image: url('~assets/icons/ic_next.svg');
    }

    &-whiteSearch {
        background-image: url('~assets/icons/ic_search.svg');
    }

    &-blackSearch {
        background-image: url('~assets/icons/ic_search_black.svg');
    }

    &-tracking {
        background-image: url('~assets/icons/ic_tracking.svg');
    }

    &-guide {
        background-image: url('~assets/icons/ic_guide.svg');
    }
}
