.t-paymentInfo {
    padding: rem(24) rem(28);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    @include mobile-down {
        padding-left: rem(16);
        padding-right: rem(16);
    }

    &_content {
        display: flex;

        @include tablet-down {
            display: block;
        }

        &-left {
            flex-basis: 50%;

            @include desktop-down {
                flex-basis: 60%;
            }
        }

        &-right {
            flex-basis: 50%;

            @include desktop-down {
                flex-basis: 40%;
            }
        }
    }

    &_orderInfo {
        max-width: rem(292);
        margin-top: rem(39);

        @include mobile-down {
            margin-top: rem(20);
        }

        &-item {
            @include adjust-flex(space-between, center);

            &:not(&:first-child) {
                margin-top: rem(24);

                @include mobile-down {
                    margin-top: rem(12);
                }
            }
        }
    }

    &_customerInfo {
        margin-top: rem(61);
        margin-bottom: rem(23);
        max-width: rem(550);

        @include desktop-down {
            max-width: unset;
        }

        @include mobile-down {
            margin-top: rem(20);
        }

        &-wrapItem {
            &:not(&:first-child) {
                border-top: rem(1) solid $gainsboro;
                margin-top: rem(20);
            }
        }

        &-item {
            @include adjust-flex(space-between, center);

            @include mobile-down {
                display: block;
            }

            padding-top: rem(24);

            @include mobile-down {
                padding-top: rem(12);
                padding-bottom: rem(10);
            }

            p {
                &:first-child {
                    flex-shrink: 0;
                    margin-right: rem(8);

                    @include mobile-down {
                        margin-right: 0;
                        margin-bottom: rem(6);
                    }
                }
            }

            span {
                margin-left: rem(5);
            }
        }
    }

    &_QRBackground {
        width: rem(382);
        height: rem(400);
        margin-left: auto;
        // margin-right: rem(30);

        @include tablet-down {
            margin-right: auto;
            width: rem(282);
            height: rem(300);
        }

        @include mobile-down {
            width: rem(250);
            height: rem(268);
            margin-right: auto;
            margin-top: rem(10);
        }

    }

    &_button {
        max-width: rem(210);
        margin: 0 auto;
    }
}
