.a-text {
    &-12x16 {
        @include font-base(12, 16);
    }

    &-14x22 {
        @include font-base(14, 22);
    }

    &-14x29 {
        @include font-base(14, 29);
    }

    &-14x20 {
        @include font-base(14, 20);
    }

    &-14x21 {
        @include font-base(14, 21);
    }

    &-16x24 {
        @include font-base(16, 24);
    }


    &-16x19 {
        @include font-base(16, 19);
    }


    &-16x27 {
        @include font-base(16, 27);

        @include desktop-down {
            @include font-base(14, 21);
        }
    }

    &-18x27 {
        @include font-base(18, 27);

        @include desktop-down {
            @include font-base(15, 23);
        }
    }

    &-18x23 {
        @include font-base(18, 23);
    }

    &-18x24 {
        @include font-base(18, 24);
    }

    &-20x23 {
        @include font-base(20, 23);

        @include mobile-down {
            @include font-base(18, 23);
        }
    }

    &-22x27 {
        @include font-base(22, 27);
    }

    &-24x29 {
        @include font-base(24, 29);
    }

    ul {
        margin-left: rem(42);

        @include mobile-down {
            margin-left: rem(32);
        }
    }

    @include letter-spacing();
    @include style-color();
    @include style-general();
    @include style-font-weight();
    @include style-font-family();
}
