@use 'sass:math';

@mixin text-overflow ($number: 2) {
    display: box;
    -webkit-line-clamp: ($number);
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin aspect-ratio($width: 1, $height: 1) {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-bottom: math.div($height, $width) * 100%;
    }
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars(
    $size: 4,
    $thumb-color: $dirt, 
    $track-color: $whitesmoke, 
    $border-radius-track: 100,
    $border-radius-thumb: 100,
    $border-thumb: 2
) {
    // For Internet Explorer
    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
        border-radius: $border-radius-track;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-right {
        text-align: right;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'black': $black,
    'dirt': $dirt,
    'dimGray1':$dim-gray1,
    'licorice':$licorice,
    'dimGray': $dim-gray,
    'oriolesOrange': $orioles-orange,
    'platinum':$platinum,
    'yankeesBlue': $yankees-blue,
    'greenCrayola': $green-crayola,
    'black025':$black-025,
    'sinopia': $sinopia,
    'vividTangelo': $vivid-tangelo,
    'licorice414': $licorice414,
    'prussianBlue': $prussian-blue
);

@mixin style-color {

    @each $name,
        $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}

$fonts-family-design: (
    'fontInter': $font-inter,
    'fontSanFranciscoDisplay': $font-san-francisco-display,
);

@mixin style-font-family {

    @each $name,
        $font in $fonts-family-design {
        &-#{$name} {
            font-family: $font;
        }
    }
}

@mixin letter-spacing {
    &-ls-md {
        letter-spacing: 0.0015em;
    }

    &-ls-lg {
        letter-spacing: 0.004em;
    }

    &-ls-xl {
        letter-spacing: 0.02em;
    }
}
