.t-checkoutForm {
    $root: &;

    &_header {
        padding: rem(21) rem(52) rem(25);

        @include tablet-down {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    &_form {
        padding: rem(19) rem(52) rem(41);

        @include tablet-down {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    &_wrapInput-label,
    &_wrapPulldown-label {
        display: flex;
        align-items: center;

        #{$root}-required {
            color: $red-orange;
            @include font-base(14, 22);
            margin-right: rem(10);
            flex-shrink: 0;
        }
    }

    &_wrapInput {
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(17);

        &:last-child {
            margin-bottom: 0;
        }

        &-label {
            flex: 0 0 rem(175);
            margin-top: rem(5);
            padding-right: rem(15);

            @include mobile-down {
                flex: 0 0 rem(130);
            }
        }

        &-input {
            flex-grow: 1;
            max-width: rem(400);

            @include tablet-down {
                max-width: 100%;
            }
        }
    }

    &_wrapPulldown {
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(17);

        &-label {
            flex: 0 0 rem(175);
            padding-right: rem(15);
            @include mobile-down {
                flex: 0 0 rem(130);
            }
        }

        &-content {
            flex-grow: 1;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin: 0 rem(-12);
            @include tablet-down {
                display: block;
            }
        }

        &-item {
            padding: 0 rem(12);
            flex: 0 0 calc(100%/3);
            width: 100%;

            &:nth-child(4) {
                padding: rem(17) 0 0;
                margin-left: rem(12);
                flex-basis: 100%;
                max-width: rem(400);
                @include tablet-down {
                    max-width: 100%;
                    padding: 0 rem(12);
                    margin: 0;
                }
            }
            @include tablet-down {
                width: 100%;
                margin-bottom: rem(17);
            }
        }

    }

    &_bottom {
        margin-top: rem(45);
        @include tablet-down {
            margin-top: rem(32);
        }
    }

    &_pay {
        display: flex;
        align-items: center;
        padding: rem(24) rem(52);

        @include tablet-down {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    &_payment {
        margin-left: rem(17);
    }

    &_button {
        max-width: rem(233);
        margin-left: auto;
        margin-top: rem(38);
        @include tablet-down {
            padding-top: rem(24);
            margin: auto;
        }
    }

    &_timeShip {
        display: flex;
        align-items: center;
        margin-top: rem(5);

        @include mobile-down {
            display: block;
        }

        &_radio {
            margin-left: rem(17);

            @include mobile-down {
                margin-left: 0;
                margin-top: rem(8);
            }
        }
    }
}
