.o-shoppingCartTable {
    @include adjust-flex(space-between, flex-start);
    padding: rem(24) rem(23);
    border-radius: rem(16);
    box-shadow: 0 rem(4) rem(16) $black-025;
    @include desktop-down {
        display: block;
    }

    &_left {
        flex: 1;
        padding-right: rem(24);
        overflow-x: auto;

        &_header {
            @include adjust-flex(space-between);
            margin-bottom: rem(21);
            min-height: rem(31);
        }

        &_table {
            overflow-x: auto;
            @include scroll-bars(12);

            table {
                width: 100%;
                @include tablet-down {
                    width: max-content;
                }

                thead {
                    background-color: $whitesmoke-7f7;

                    th {
                        white-space: nowrap;
                        padding: rem(25) rem(16);

                        &:first-child {
                            padding-left: rem(16);
                        }

                        &:last-child {
                            padding-right: rem(16);
                            min-width: rem(47);
                        }
                    }
                }

                tbody {
                    tr {
                        &:last-child td {
                            padding-bottom: rem(25);
                        }
                    }

                    td {
                        padding-top: rem(25);
                        padding-right: rem(16);
                        padding-left: rem(16);

                        &:first-child {
                            padding-left: rem(16);
                        }

                        &:last-child {
                            padding-right: rem(16);
                        }

                        &:nth-child(2) {
                            min-width: rem(400);
                        }

                        &:nth-child(3) {
                            min-width: rem(138);
                        }

                        &:nth-child(4) {
                            min-width: rem(105);
                        }

                        &:nth-child(5) {
                            min-width: rem(125);
                        }

                        &:nth-child(7) {
                            min-width: rem(47);
                        }
                    }
                }
            }
        }
    }

    &_right {
        flex: 0 0 rem(218);

        @include desktop-down {
            margin-top: rem(24);
        }

        @include mobile-down {
            margin-top: rem(12);
        }

        .a-button,
        textarea,
        &_extra {
            margin-top: rem(24);

            @include mobile-down {
                margin-top: rem(12);
            }
        }

        textarea {
            width: 100%;
            outline: none;
            border: 1px solid $gainsboro;
            border-radius: rem(2);
            padding: rem(5) rem(12);
            resize: none;
            @include font-base(14, 22);

            &::placeholder {
                @include font-base(14, 22);
                color: $black-025;
            }
        }

        &_extra {
            @include adjust-flex(space-between);
        }
    }

    &_productItem {
        @include adjust-flex(flex-start, stretch);

        &_img {
            width: rem(80);
            overflow: hidden;
            border-radius: rem(8);
            flex-shrink: 0;
        }

        &_info {
            @include adjust-flex(space-between, flex-start);
            flex-grow: 1;
            flex-direction: column;
            margin-left: rem(14);

            &-option {
                width: 100%;
            }
        }
    }

    &_quantity {
        .a-input {
            max-width: rem(90);
        }
    }

    &_iconButton {
        @include reset-button;
        background-color: transparent;
    }
}
