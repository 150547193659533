.o-introduceFeature {
    &_wrap {
        // @include adjust-flex(flex-start);
        // flex-wrap: wrap;
        margin: 0 rem(-20);

        @include tablet-down {
            margin: 0 rem(-8);
        }

        @include mobile-down {
            margin: 0;
        }
    }

    &_col {
        position: relative;
        // @include adjust-flex;
        // flex: 0 0 calc(100% / 3);
        // margin-top: rem(12);
        // margin-bottom: rem(12);

        padding: 0 rem(20);

        @include tablet-down {
            padding: 0 rem(8);
        }

        @include mobile-down {
            padding: 0 rem(6);
        }

        // &:not(:last-child) {
        //     &::before {
        //         position: absolute;
        //         content: '';
        //         top: 50%;
        //         right: 0;
        //         transform: translateY(-50%);
        //         width: 1px;
        //         height: rem(60);
        //         background-color: $gainsboro;

        //         @include tablet-down {
        //             height: rem(45);
        //         }

        //         @include mobile-down {
        //             display: none;
        //         }
        //     }
        // }

        &-img {
            position: relative;
            width: 100%;
            // max-width: rem(55);
        }

        &-text {
            max-width: rem(250);

            @include tablet-down {
                max-width: 100%;
            }
        }
    }
}
