.o-pagination {
    @include adjust-flex;

    .main {
        display: flex;
        align-items: center;
    }

    .page {
        height: fit-content;
        width: fit-content;
        background: transparent;
        box-sizing: border-box;
        cursor: pointer;
        list-style-type: none;
        margin: 0 rem(8);
        overflow: hidden;
        transition: $transition;
        isolation: isolate;

        &:first-child,
        &:last-child {
            width: rem(26);
            height: rem(26);
            border-radius: 50%;
            border: 1px solid $vivid-tangelo;

            &.disabled {
                border: 1px solid $black-02;
            }
        }

        &:not(.disabled):hover {
            .link-page {
                color: $vivid-tangelo;
            }
        }

        @include mobile-down {
            margin: 0 rem(6);
        }

        .link-page {
            @include adjust-flex;
            width: 100%;
            height: 100%;
            padding: 0;
            @include font-base(18, 27);
            font-weight: 500;
            color: $licorice414;
            font-family: $font-san-francisco-display;
            outline: none;

            .a-icon {
                flex-shrink: 0;

                @include tablet-down {
                    width: rem(8);
                    height: rem(8);
                }
            }
        }
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .active-page {
        .link-page {
            color: $vivid-tangelo;
        }
    }
}
