.m-pulldown {
    $root: &;
    position: relative;
    background: transparent;
    
    &_header {
        height: rem(32);
        align-items: center;
        background: transparent;
        border: 1px solid $gainsboro;
        border-radius: rem(2);
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 100%;
        z-index: 1;
        padding: rem(5) rem(12);
        #{$root}-sm & {
            max-height: rem(48);
        }

        #{$root}-md & {
            max-height: rem(55);
        }

        #{$root}-lg & {
            max-height: rem(60);
        }

        &_content {
            align-items: center;
            display: flex;
            flex-grow: 1;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $charleston-green;
            @include font-base(14, 22);

            span {
                @include text-overflow(1);
            }
        }
    }

    &-disabled {
        #{$root}_header {
            cursor: auto;
            opacity: 0.7;
            pointer-events: none;
        }
    }

    &_search {
        background: transparent;
        border: 0;
        color: $charleston-green;
        @include font-base(14, 22);
        height: 100%;
        left: 0;
        padding: rem(5) rem(12);
        position: absolute;
        top: 0;
        width: 100%;
    
        &:focus {
            outline: none;
        }
    }

    &_placeholder {
        color: $black-025;
        @include font-base(12, 22);
    }

    &_fn {
        align-items: center;
        display: flex;
        flex-shrink: 0;
    }

    &_arrow {
        display: block;
        position: relative;
        height: rem(8);
        width: rem(8);

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: $transition;
            background: url('~assets/icons/ic_arrow_down_gray.svg') no-repeat;
            background-position: center !important;
            background-size: contain;
        }

        &.opened {
            &::after {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
    }

    &_wrapper {
        background: $white;
        margin-top: rem(4);
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    &_list {
        border: 1px solid $gainsboro;
        color: $charleston-green;
        margin: 0;
        max-height: rem(161);
        overflow-y: auto;
        padding: 0;
        @include font-base(14, 22);
        @include scroll-bars;
    }

    &_item {
        cursor: pointer;
        list-style: none;
        padding: rem(11) rem(12);
        transition: all $transition linear;
        white-space: normal;

        &:hover {
            background-color: rgba($dirt, 0.6);
            color: $white;
        }

        &.none {
            color: $dim-gray;
            text-align: center;
        }
    }

    &-error {
        border-color: $sinopia !important;
        color: $sinopia;
        #{$root}_header {
            border-color: $sinopia !important;
            color: $sinopia;
        }
    }

    &_error {
        color: $sinopia;
        display: block;
        margin-top: rem(4);
        opacity: 0.8;
        @include font-base(12, 16);
    }
}
