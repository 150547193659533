.t-heroBanner {
    position: relative;

    &_item {
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        height: calc(500 / 768 * 100vh);

        @include iphone-large-lands {
            height: 100vh;
        }
    }

    &_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: rem(750);

        @include tablet-down {
            max-width: rem(720);
        }
    }

    &_button {
        max-width: rem(145);
        width: 100%;

        @include desktop-down {
            max-width: rem(115);
        }
    }

    .o-carousel {
        .slick-dots {
            bottom: rem(18);
        }
    }
}
