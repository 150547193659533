.a-textarea {
    $root: &;
    position: relative;
    background: transparent;
    @include font-base(12, 22);

    &_textarea {
        border: 1px solid $gainsboro;
        border-radius: rem(2);
        padding: rem(5) rem(12);
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        min-height: rem(32);
        font-family: $font-san-francisco-display;
        font-weight: 400;
        color: $black-085;
        

        &::placeholder {
            color: $black-025;
        }

        &:focus {
            #{$root} {
                border: 1px solid $dirt;
            }
        }

        &:disabled {
            background-color: $whitesmoke;
        }
    }

    &-formContact {
        @include font-base(16, 24);

        #{$root}_textarea {
            padding-top: rem(8);
            padding-bottom: rem(8);
            border-style: solid;
            border-radius: rem(4);
            border: 1px solid $white;
            color: $black-060;
        }
    }

    &-error {
        border-color: $sinopia !important;
        color: $sinopia;
        #{$root}_textarea {
            border-color: $sinopia !important;
            color: $sinopia;
        }
    }

    &_error {
        color: $sinopia;
        display: block;
        opacity: 0.8;
        @include font-base(12, 16);
    }
}
