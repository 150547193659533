.a-radio {
    $root: &;

    &_wrapper {
        @include adjust-flex;
        cursor: pointer;
        width: fit-content;
    }

    input {
        position: relative;
        appearance: none;
        cursor: pointer;
        margin: 0 rem(9) 0 0;
        width: rem(14.5);
        height: rem(14.5);
        border: rem(1.5) solid $eerie-black;
        border-radius: 50%;
        transition: border-color $transition ease-in-out;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: rem(1.5);
            background-color: $dirt;
            top: 50%;
            left: 50%;
            opacity: 0;
            transition: opacity $transition ease-in-out;
        }

        &::before {
            width: rem(8);
            transform: translate(-35%, -25%) rotate(-45deg);
        }

        &::after {
            width: rem(4);
            transform: translate(-100%, 70%) rotate(45deg);
        }

        &:checked {
            border-color: $dirt;
            background-color: $dirt-03;

            &::before,
            &::after {
                opacity: 1;
            }
        }
    }
}
