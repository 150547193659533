.m-collapse {
    &_head {
        padding-bottom: rem(11);
        @include adjust-flex(space-between, center);
        cursor: pointer;
        position: relative;

        &::after {
            border-bottom: 1px solid #ededed;
            content: '';
            position: absolute;
            bottom: 0;
            left: -100%;
            right: -100%;
            height: rem(1);
            background: #ededed;
        }
    }

    &_wrapContent {
        transition: all 0.3s ease-in-out;
        visibility: hidden;
        padding-left: rem(68);
        background-color: $white;
        max-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        opacity: 0;

        &-show {
            visibility: visible;
            opacity: 1;
        }
    }
}
