.a-button {
    appearance: none;
    outline: none;
    border: 1px solid $dirt;
    background-color: $dirt;
    border-radius: rem(4);
    width: 100%;
    height: rem(52);
    padding: rem(13) rem(30);
    color: $white;
    transition: all $transition ease-in-out;
    @include font-base(16, 24);
    font-weight: 500;
    position: relative;

    @include tablet-down {
        padding: rem(12) rem(22);
    }

    &:hover {
        background-color: $licorice;
    }

    &-eff2 {
        &:hover {
            background-color: $white;

            .a-text {
                color: $vivid-tangelo;
            }
        }
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    &-link {
        display: block;
        text-align: center;

        &:hover {
            color: $white;
        }
    }

    &-loading {
        pointer-events: none;
    }

    &_loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-white {
        color: $licorice;
        border-color: $white;
        background-color: $white;
        box-shadow: 0 rem(4) rem(25) $cyan-azure-015;

        &:hover {
            color: $white;
        }
    }

    &-outline {
        color: $black;
        border-color: $gray-x11;
        background-color: transparent;

        &:hover {
            color: $vivid-tangelo;
            border-color: $vivid-tangelo;
            background-color: transparent;
        }
    }

    &-orange {
        color: $white;
        border: 0;
        border-radius: rem(4);
        background-color: $vivid-tangelo;

        &:hover {
            background-color: $vivid-tangelo;
            opacity: 0.8;
        }
    }

    &-vividTangelo {
        color: $white;
        border-color: $vivid-tangelo;
        background-color: $vivid-tangelo;
        box-shadow: 0 rem(4) rem(25) $cyan-azure-015;
        padding: rem(12) rem(24);

        &:hover {
            background-color: $vivid-tangelo-07;
            border-color: $vivid-tangelo-07;
        }
    }

    &-lg {
        height: rem(54);
        padding: rem(14) rem(30);
    }

    &-sm {
        height: rem(50);
        padding: rem(12) rem(22);
    }

    &-xs {
        height: rem(36);
        padding: rem(7) rem(22);
    }

    &-no-radius {
        border-radius: unset;
    }

    &-no-padding {
        padding: rem(13) 0;
    }
}
