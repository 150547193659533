/* stylelint-disable selector-no-qualifying-type */
.o-addCartToast {
    $root: &;

    &_floatingIcon {
        transition: $transition;
        visibility: visible;
        transform: translateX(calc(100% - 85px));
        z-index: 2;
        position: fixed;
        top: 25%;
        right: 0;

        @include tablet-down {
            margin-top: rem(50);
        }

        @include mobile-down {
            margin-top: rem(30);
            transform: translateX(calc(100% - 70px));
        }

        &-hide {
            visibility: hidden;
            transform: translateX(100%);
        }
    }

    &_floatingIcon_goSanDeal {
        transition: $transition;
        visibility: visible;
        transform: translateX(calc(100% - 85px));
        z-index: 2;
        position: fixed;
        top: 25%;
        right: 0;

        @include tablet-down {
            margin-top: rem(50);
        }

        @include mobile-down {
            margin-top: rem(30);
            transform: translateX(calc(100% - 70px));
        }
    }

    &_icon {
        background: $dirt;
        border-radius: 50%;
        box-shadow: 0 rem(4) rem(4) $dirt-05;
        width: 55px;
        height: 55px;
        @include adjust-flex();
        cursor: pointer;

        &-home {
            background: $white;
        }
    }

    &_form {
        transform: translateX(100%);
        transition: $transition;
        box-shadow: 0 rem(4) rem(4) $dirt-05;
        background-color: $white;
        min-width: rem(329);
        padding: rem(15) rem(21) rem(10);
        border-top-left-radius: rem(24);
        border-bottom-left-radius: rem(24);
        visibility: hidden;
        z-index: 2;
        position: fixed;
        top: 25%;
        right: 0;

        @include tablet-down {
            margin-top: rem(50);
        }

        @include mobile-down {
            margin-top: rem(30);
        }
    }

    &_icon-home {
        visibility: visible;
        transform: translateX(calc(100% - 85px));
        position: fixed;
        top: 20%;
        z-index: 2;
        right: 0;

        @include mobile-down {
            transform: translateX(calc(100% - 70px));
        }
    }

    &-show {
        #{$root} {
            &_form {
                transform: translateX(0);
                visibility: visible;
            }

            &_floatingIcon {
                visibility: hidden;
                transform: translateX(100%);
            }
        }
    }

    @include mobile-down {
        border-top-left-radius: rem(24);
        border-bottom-left-radius: unset;
        border-top-right-radius: rem(24);
    }

    &_temporaryPrice,
    &_quantity,
    &_note,
    &_footer {
        @include adjust-flex(flex-start);
        margin-top: rem(8);
        margin-bottom: rem(8);
    }

    &_temporaryPrice {
        .a-text {
            margin-right: rem(11);
        }
    }

    &_label {
        width: 83px;
    }

    &_item {
        &-quantity {
            width: rem(90);
        }
    }

    &_quantity,
    &_note {
        display: flex;
        align-items: flex-start;

        .a-text {
            margin-top: rem(8);
            margin-right: rem(17);
        }

        input {
            height: rem(32);
            padding: rem(5) rem(12);
            outline: none;
            border: 1px solid $gainsboro;
            border-radius: rem(2);
            @include font-base(14, 22);
        }
    }

    &_note {
        #{$root}_item {
            flex: 1;
        }
    }

    &_footer {
        &_icon {
            flex-basis: rem(65);
        }

        &_actions {
            flex-grow: 1;
            flex-basis: auto;
            @include adjust-flex(space-between);

            button {
                flex-basis: rem(123);
                padding: rem(13) 0;
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
